
<template>
  <CCard>
    <CCardHeader class="text-center bg-dark text-white">
      <b>{{$t('label.eirList')+' - '+$t('label.transactions')}}</b>
    </CCardHeader>
    <CCardBody>
        <CustomTabs :active-tab="Tab" @update:activeTab="handleTab">
          <CustomTab :title="$t('label.eirType')" icon="eirType">
              <eirTypeIndex :Tab="Tab"/>   
            </CustomTab>
            <CustomTab :title="$t('label.eirTransaction')" icon="eirTransaction">
              <eirTransactionIndex :Tab="Tab"/>
            </CustomTab>
        </CustomTabs>
    </CCardBody>
  </CCard>
</template>
<script>
import CustomTabs from '@/components/tabs/CustomTabs';
import CustomTab from '@/components/tabs/CustomTab';
import eirTypeIndex from './eirType/eirType-index';
import eirTransactionIndex from './eirTransaction/eirTransaction-index';

function handleTab(tab) {
  this.Tab = tab;
}
function data() {
  return {
    Tab: 0,
  };
}
export default {
  name: 'index',
  data,
  methods:{
    handleTab,
  },
  components: {
    CustomTabs,
    CustomTab,
    eirTypeIndex,
    eirTransactionIndex
  },
};
</script>